import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import Grid from "styled-components-grid";
import { Margin, mb, pr } from "styled-components-spacing";
import Layout from "../components/Layout";
import Header from "../components/GatsbyHeader";
import Heading from "../components/Heading";
import Markdown from "../components/Markdown";
import Section from "../components/Section";
import GoogleMap from "../components/GoogleMap";
import Content, { HTMLContent } from "../components/Content";
import envelope from "../img/zondicons/envelope.svg";
import network from "../img/zondicons/network.svg";
import phoneIcon from "../img/zondicons/phone.svg";
import location from "../img/zondicons/location.svg";
import send from "../img/zondicons/send.svg";

const ContactMethods = styled.div`
  display: flex;
  flex-wrap: wrap;

  @supports (display: grid) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(288px, 1fr));
    grid-gap: 2rem;

    ${breakpoint("tablet")`
      margin: 0;
      padding: 0;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 3rem;
    `}

    ${breakpoint("desktop")`
      grid-gap: 4rem;
    `}
  }
`;

const ContactIcon = styled.img`
  display: inline-block;
  margin-right: 12px;
`;

const ContactMethodItem = styled.div`
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  ${mb(4)};

  ${breakpoint("tablet")`
    flex-basis: 50%;
    max-width: 50%;
    
    &:nth-child(2n + 1) {
      ${pr(3)};
    }
  `}

  ${breakpoint("desktop")`
    flex-basis: 33.33%;
    max-width: 33.33%;
    ${pr(3)};

    &:nth-child(3n + 3) {
      ${pr(0)};
    }
  `}

  @supports (display: grid) {
    max-width: none;
    ${mb(0)};
    ${pr(0)};
  }
`;

const ContactMethod = props => (
  <ContactMethodItem>
    <Margin bottom={{ mobile: 3, tablet: 4 }}>
      <Grid wrap={false} valign="center">
        <Grid.Unit size="min" style={{ fontSize: "0" }}>
          <ContactIcon src={props.icon} width="24" height="24" />
        </Grid.Unit>
        <Grid.Unit size="max">
          <Heading size={5} style={{ fontWeight: "500" }}>
            {props.title}
          </Heading>
        </Grid.Unit>
      </Grid>
    </Margin>
    {props.children}
  </ContactMethodItem>
);

export const ContactPageTemplate = ({
  title,
  content,
  image,
  companyDetails,
  officeAddress,
  postalAddress,
  phone,
  email,
  contentComponent
}) => {
  const PageContent = contentComponent || Content;

  return (
    <React.Fragment>
      <Header
        imageData={
          image && !!image.childImageSharp ? image.childImageSharp.fluid : image
        }
        title={title}
        position="bottom"
      />
      <Section>
        <ContactMethods>
          <ContactMethod icon={network} title="Company details:">
            <Markdown content={companyDetails} />
          </ContactMethod>

          <ContactMethod icon={location} title="Office address:">
            <Markdown content={officeAddress} />
          </ContactMethod>

          <ContactMethod icon={envelope} title="Postal address:">
            <Markdown content={postalAddress} />
          </ContactMethod>

          <ContactMethod icon={phoneIcon} title="Phone:">
            <Markdown content={phone} />
          </ContactMethod>

          <ContactMethod icon={send} title="Email:">
            <Markdown content={email} />
          </ContactMethod>
        </ContactMethods>
      </Section>
      <GoogleMap />
    </React.Fragment>
  );
};

ContactPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func
};

const ContactPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout showCta={false}>
      <ContactPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        image={post.frontmatter.image}
        companyDetails={post.frontmatter.companyDetails}
        officeAddress={post.frontmatter.officeAddress}
        postalAddress={post.frontmatter.postalAddress}
        phone={post.frontmatter.phone}
        email={post.frontmatter.email}
        content={post.html}
      />
    </Layout>
  );
};

ContactPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default ContactPage;

export const ContactPageQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 1600, quality: 60) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        companyDetails
        officeAddress
        postalAddress
        phone
        email
      }
    }
  }
`;
