import React from "react";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";

const Map = styled.div`
  position: relative;
  padding-bottom: 75%; /* 16:9 */
  height: 0;
  overflow: hidden;
  background-color: ${props =>
    props.theme && props.theme.colors ? props.theme.colors.f5 : "#f2f2f2"};

  ${breakpoint("tablet")`
    padding-bottom: 56.25%; /* 16:9 */
  `}

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    border: 0;
  }

  @media screen and (min-width: 60em) {
    padding-bottom: 50vh;

    iframe {
      max-height: 50vh;
    }
  }
`;

const GoogleMap = () => (
  <Map>
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3347.487634581613!2d151.6955807!3d-32.9645343!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b733d8b925d929f%3A0x3ec3b1ae188fec81!2s7%2F153+Pacific+Hwy%2C+Charlestown+NSW+2290!5e0!3m2!1sen!2sau!4v1565940060164!5m2!1sen!2sau"
      frameBorder="0"
      title="Map of location of Skelton Consulting Engineers"
      allowFullScreen
    />
  </Map>
);

export default GoogleMap;
